
import { Vue, Component } from 'vue-property-decorator';
import EmailInput from '@/components/inputs/EmailInput.vue';
import userx from '@/store/modules/userx';
import { Route, RawLocation } from 'vue-router';
import { EditState } from '@/store/models.def';
import StateButton from '@/components/inputs/StateButton.vue';

@Component({
	components: {
		EmailInput,
		StateButton,
	},
})
export default class Login extends Vue {
	public email = '';
	public validateEmailNow = false;
	public saveEmailError: string = '';
	public saveEmailSuccess: string = '';
	public saveEmailState: EditState = 'active';

	public continueUrl = '/';

	public get saveEmailLabel() {
		const labels = {
			active: 'Send Reset Password Email',
			sending: 'Sending...',
			done: 'Reset Password Email Sent',
		};
		return labels[this.saveEmailState];
	}
	public async sendPasswordResetEmail() {
		if (this.saveEmailState !== 'active') {
			return;
		}
		this.validateEmailNow = true;
		if (this.email !== null) {
			this.saveEmailState = 'sending';
			const result = await userx.sendPasswordResetEmail(this.email);
			if (result.success) {
				this.saveEmailState = 'done';
				this.saveEmailSuccess = 'Success';
			} else {
				this.saveEmailError = result.errorMessage!;
				this.saveEmailState = 'active';
			}
		}
	}
	public beforeRouteEnter(
		to: Route,
		from: Route,
		next: (to?: RawLocation | false | ((vm: Login) => any) | void) => void,
	): void {
		next((vm) => {
			vm.email = to.query.email ? (to.query.email as string) : '';
		});
	}
}
